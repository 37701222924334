<template>
  <div v-for="(nodes, index) in myArray" :key="index">
    <form
      class="nodes"
      :style="{
        backgroundColor: nodes.node_color,
      }"
    >
      <template v-if="nodes.node_readmode == false">
        <textarea
          v-model="nodes.node_text"
          @input="editNode"
          :id="nodes.node_id"
          ref="textentry"
          placeholder="Type your thoughts and ideas here! (auto saved every keystroke)"
          rows="5"
          @mouseup="getSelected($event)"
        ></textarea>
        <p class="info">*markdown supported &amp; autosaves</p>
        <div>
          <button @click.prevent="makeH1">Header 1</button>
          <button @click.prevent="makeH2">Header 2</button>
          <button @click.prevent="makeH3">Header 3</button>
        </div>
        <div>
          <button @click.prevent="makeBold">Bold</button>
          <button @click.prevent="makeItalic">Italic</button>
          <button @click.prevent="makeLink">Link</button>
          <button @click.prevent="makeImage">Image</button>
        </div>
        <VSwatches
          v-model="nodes.node_color"
          :swatches="swatches"
          :shapes="shapes"
          @update:modelValue="chooseColor(nodes.node_id, nodes.node_color)"
          show-border
          show-fallback
          fallback-input-type="color"
        >
          <template v-slot:trigger>
            <button @click.prevent>{{ nodes.node_color }}</button>
          </template>
        </VSwatches>
        <select
          v-model="nodes.node_shape"
          @input="setShape"
          :id="nodes.node_id"
        >
          <option value="square">Square</option>
          <option value="circle">Circle</option>
          <option value="triangle">Triangle</option>
          <option value="hexagon">Hexagon</option>
        </select>

        <button @click.prevent="toggleMode(nodes.node_id)">Read</button>
        <button @click.prevent="discardNode(nodes.node_id)">Discard</button>
      </template>
      <template v-else>
        <p
          class="readmode"
          :id="nodes.node_id"
          v-html="marked(nodes.node_text)"
        ></p>
        <button @click.prevent="toggleMode(nodes.node_id)">Edit</button>
        <div class="allemoji">
          <div
            class="eachemoji"
            v-for="(emojis, index) in allEmoji.allEmoji"
            :key="index"
          >
            <p v-if="nodes.node_id == emojis.node_id">
              {{ emojis.emoji_unicode }}
            </p>
          </div>
        </div>
      </template>
    </form>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapState } from 'vuex'
import VSwatches from 'vue3-swatches'
import marked from 'marked'

export default {
  name: 'MyNodes',

  components: { VSwatches },

  // directives: {
  //   focus: {
  //     mounted(el) {
  //       el.focus()
  //     },
  //   },
  // },

  props: {
    added: Boolean,
  },

  data() {
    return {
      nodeid: String,
      start: String,
      end: String,
      selection: String,
      myArray: [],
      shapes: 'circles',
      // swatches: [{ color: '#F493A7', showBorder: true }],
      swatches: [
        ['#EB5757', '#F2994A', '#F2C94C'],
        ['#219653', '#27AE60', '#6FCF97'],
        ['#2F80ED', '#2D9CDB', '#56CCF2'],
        ['#9B51E0', '#BB6BD9', '#E9B7FC'],
      ],
    }
  },

  computed: {
    ...mapState({
      myNodes: (state) => state.myNodes,
      allEmoji: (state) => state.allEmoji,
    }),
  },

  watch: {
    added: function () {
      setTimeout(this.loadData, 500)
    },
  },

  mounted() {
    // this.$store.dispatch('getEmoji')
    // this.$store.dispatch('getPositions')
    setTimeout(this.loadData, 500)
    if (localStorage.nogg_name && localStorage.nogg_microcosm) {
      var devicename = localStorage.nogg_name
      var microcosm = localStorage.nogg_microcosm
      this.$store.dispatch('setMicrocosm', { devicename, microcosm })
      // this.$store.dispatch('getEmoji')
      // this.$store.dispatch('getPositions')
    } else {
      console.log('no')
      // go home
    }
  },

  methods: {
    marked,

    getSelected(e) {
      this.selection = e.target.value.substring(
        e.target.selectionStart,
        e.target.selectionEnd
      )
      this.start = e.target.selectionStart
      this.end = e.target.selectionEnd
      this.nodeid = e.target.id
    },

    makeH1() {
      const symbol = '# '
      var text = `${symbol}${this.selection}`
      var textarea = document.getElementById(this.nodeid)
      textarea.setRangeText(text, this.start, this.end, 'select')
      this.editNodeStyle(this.nodeid, textarea.value)
    },

    makeH2() {
      const symbol = '## '
      var text = `${symbol}${this.selection}`
      var textarea = document.getElementById(this.nodeid)
      textarea.setRangeText(text, this.start, this.end, 'select')
      this.editNodeStyle(this.nodeid, textarea.value)
    },

    makeH3() {
      const symbol = '### '
      var text = `${symbol}${this.selection}`
      var textarea = document.getElementById(this.nodeid)
      textarea.setRangeText(text, this.start, this.end, 'select')
      this.editNodeStyle(this.nodeid, textarea.value)
    },

    makeBold() {
      const symbol = '**'
      var text = `${symbol}${this.selection}${symbol}`
      var textarea = document.getElementById(this.nodeid)
      textarea.setRangeText(text, this.start, this.end, 'select')
      this.editNodeStyle(this.nodeid, textarea.value)
    },

    makeItalic() {
      const symbol = '*'
      var text = `${symbol}${this.selection}${symbol}`
      var textarea = document.getElementById(this.nodeid)
      textarea.setRangeText(text, this.start, this.end, 'select')
      this.editNodeStyle(this.nodeid, textarea.value)
    },

    makeLink() {
      const one = '['
      const two = ']'
      const three = '('
      const four = ')'

      var text = `${one}${
        this.selection
      }${two}${three}${'https://urlhere'}${four}`
      var textarea = document.getElementById(this.nodeid)
      textarea.setRangeText(text, this.start, this.end, 'select')
      this.editNodeStyle(this.nodeid, textarea.value)
      var cursorplace = this.end + 3
      console.log(cursorplace)
      textarea.focus()
      textarea.setSelectionRange(cursorplace, cursorplace + 15)
    },

    makeImage() {
      const one = '!['
      const two = ']'
      const three = '('
      const four = ')'
      var text = `${one}${
        this.selection
      }${two}${three}${'imagelinkhere'}${four}`
      var textarea = document.getElementById(this.nodeid)
      textarea.setRangeText(text, this.start, this.end, 'select')
      this.editNodeStyle(this.nodeid, textarea.value)
      var cursorplace = this.end + 4
      console.log(cursorplace)
      textarea.focus()
      textarea.setSelectionRange(cursorplace, cursorplace + 13)
    },

    loadData() {
      var nodesFiltered = this.myNodes.myNodes.filter(
        (nodes) => nodes.node_deleted == false
      )
      // this should probably be on the tool bar NOT HERE really
      this.$store.dispatch('getMynodes')
      this.$store.dispatch('getEmoji')
      this.$store.dispatch('getPositions')

      this.myArray = nodesFiltered.reverse()
    },

    editNodeStyle(nodeid, nodetext) {
      this.$store.dispatch('editNode', { nodeid, nodetext })
    },

    editNode(e) {
      var nodeid = e.target.id
      var nodetext = e.target.value
      this.$store.dispatch('editNode', { nodeid, nodetext })
    },

    discardNode(e) {
      if (confirm('Confirm discard?')) {
        this.$store.dispatch('discardNode', { e })
        setTimeout(this.loadData, 500)
      } else {
        // nothing happens
      }
    },

    toggleMode(e) {
      this.$store.dispatch('toggleMode', { e })
      setTimeout(this.loadData, 500)
    },

    chooseColor(nodeid, color) {
      this.$store.dispatch('colorNode', { nodeid, color })
      setTimeout(this.loadData, 500)
    },

    setShape(e) {
      var nodeid = e.target.id
      var shape = e.target.value

      this.$store.dispatch('setShape', { nodeid, shape })
      setTimeout(this.loadData, 500)
    },
  },
}
</script>

<style>
h1 {
  margin: 0em;
  padding: 0em;
  font-size: 4.2em;
  font-family: houschka-rounded, sans-serif;
  font-weight: 700;
  font-style: normal;
  color: black;
}

h2 {
  margin: 0em;
  padding: 0em;
  font-size: 3.2em;
  font-family: houschka-rounded, sans-serif;
  font-weight: 700;
  font-style: normal;
  color: black;
}

h3 {
  margin: 0em;
  padding: 0em;
  font-size: 2.2em;
  font-family: houschka-rounded, sans-serif;
  font-weight: 700;
  font-style: normal;
  color: black;
}

.nodes {
  width: 95%;
  border: 2px dashed black;
  background-color: rgb(155, 194, 216);
  margin-top: 1em;
  margin-left: 0.5em;
}

textarea {
  font-size: 1em;
  resize: none;
  border: none;
  background-color: rgb(187, 225, 247);
  width: 100%;
  box-sizing: border-box;
}

img {
  width: 45%;
}

.allemoji {
  font-size: 1.5em;
  padding: 0em;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(0, auto));
}
</style>
