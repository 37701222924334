<template>
  <div>
    <button @click="addNode()">+ Add Node</button>
    <!-- <button>Select Node</button>
    <button>Make Connections</button>
    <UploadMedia /> -->
    <button class="leave" @click="exitMicrocosm()">
      &lt;- Leave Microcosm
    </button>
    <!-- <p>{{ microcosm }}</p> -->
  </div>
</template>

<script>
// @ is an alias to /src
// import UploadMedia from '@/components/UploadMedia.vue'
import shortcuts from '@/mixins/shortcuts'

export default {
  name: 'ToolBar',
  components: {
    // UploadMedia,
  },
  data() {
    return {
      microcosm: 'microcosm name',
    }
  },

  mounted() {
    this.microcosm = localStorage.getItem('nogg_microcosm')
  },

  mixins: [
    shortcuts('n', function () {
      // n key pressed
      this.addNode()
    }),
    shortcuts('c', function () {
      // c key was pressed
      console.log('c key was pressed')
    }),
  ],

  methods: {
    addNode() {
      this.$emit('added-node')
      this.$store.dispatch('addNode')
    },
    exitMicrocosm() {
      localStorage.removeItem('nogg_microcosm')
      localStorage.removeItem('nogg_name')

      location.assign(
        process.env.VUE_APP_HTTP + '://' + process.env.VUE_APP_URL + '/'
      )
    },
  },
}
</script>

<style scoped>
.leave {
  /* background-color: red; */
}
</style>
